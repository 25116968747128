import { miniauniversity, hackathon, htmlsololearn, htmcssmahara, pythonhacker, javahacker, djangobarmeg, fullstackbarmeg, uxuiudemy, communication } from '../../../images/images';

export const CertData = [
	{
		'img': miniauniversity,
		'des': 'minia university Certificate',
		'i':5,
	},
	{
		'img': communication,
		'des': 'communicatin and  Certificate ',
		'i':2,
	},
	{
		'img': hackathon,
		'des': 'boot camb Hackthon Certificate ',
		'i':3,
	},
	{
		'img': htmlsololearn,
		'des': 'sololearn Certificate ',
		'i':4,
	},
	{
		'img': htmcssmahara,
		'des': 'mahara tech in html Certificate ',
		'i':5,
	},
	{
		'img': pythonhacker,
		'des': 'hackerrank Certificate in python',
		'i':6,
	},
	{
		'img': javahacker,
		'des': 'hackerrank Certificate in javascript',
		'i':7,
	},
	{
		'img': djangobarmeg,
		'des': 'barmeg Certificate in django ',
		'i': 8,
	},
	{
		'img': fullstackbarmeg,
		'des': 'barmeg Certificate in fullstack web developer',
		'i': 9,
	},
	{
		'img': uxuiudemy,
		'des': 'barmeg Certificate in design',
		'i': 10,
	}

];