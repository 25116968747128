import { pro1, pro2, pro3, pro4, pro5, pro6, segog11, segog12, segog3, sinkropro1, sinkropro2, sinkropro4, sinkrow6, staaw10, staaw11, } from '../../../images/images';

export const prodata = [
	{
		id: 5,
		img: pro4,
		link: pro4,
		des: 'success maintenance motherboard',
		dep:'SEgOg'
	},

	{
		id: 6,
		img: segog11,
		link: segog11,
		des: 'maintenance of hard disk',
		dep:'SEgOg'
	},

	{
		id: 7,
		img: segog12,
		link: segog12,
		des: 'success maintenance of hard disk',
		dep:'SEgOg'
	},

	{
		id: 8,
		img: segog3,
		link: segog3,
		des: 'success maintenance PC in minia university',
		dep:'SEgOg'
	},


	{
		id: 1,
		img: pro1,
		link: 'https://saske-store.netlify.app',
		des: 'Store Project v-1',
		dep:'SACODY'
	},

	{
		id: 2,
		img: pro3,
		link: 'https://sultan-pasha.github.io/hotel/',
		des: 'Hotel project v-4',
		dep:'SACODY'
	},

	{
		id: 16,
		img: pro6,
		link: pro6,
		des: 'work for mawjood startup\'s site',
		dep:'SACODY'
	},


	{
		id: 3,
		img: pro5,
		link: 'https://sultan-pasha.github.io/hospitalsite/',
		des: 'Hospital v-1',
		dep:'SACODY'
	},

	{
		id: 4,
		img: pro2,
		link: pro2,
		des: 'source code from School project v-1',
		dep:'SACODY'
	},

	{
		id: 9,
		img: sinkropro1,
		link: sinkropro1,
		des: 'our backup server',
		dep:'SINKROW'
	},

	{
		id: 10,
		img: sinkropro2,
		link: sinkropro2,
		des: 'proccess on our backup server',
		dep:'SINKROW'
	},

	{
		id: 11,
		img: sinkropro4,
		link: sinkropro4,
		des: 'rebuilding servers of minia university',
		dep:'SINKROW'
	},

	{
		id: 12,
		img: sinkrow6,
		link: sinkrow6,
		des: 'our server to control nerwork',
		dep:'SINKROW'
	},

	{
		id: 14,
		img: staaw10,
		link: 'https://5e7c20b09420a.site123.me/',
		des: 'store to provide our services & products',
		dep:''
	},

	{
		id: 15,
		img: staaw11,
		link: staaw11,
		des: 'some of cusomers order from staaw',
		dep:''
	},
];