import { bofathey } from "../../../images/images";
import { alaa } from "../../../images/images";
import { mido } from "../../../images/images";
import { eslam } from "../../../images/images";
import { assem } from "../../../images/images";
import { raaef } from "../../../images/images";

export let person = [
	{
		id: 2,
		name: "Ayaat Hashem",
		role: "manager assistant",
		address: "minia",
		tel: "0",
		dep: "SASKE",
		img: "",
		alt: "",
	},
	{
		id: 11,
		name: "nehal abdelkareem",
		role: "designer assistant",
		address: "minia",
		tel: "0",
		dep: "SASKE",
		img: "",
		alt: "",
	},
	{
		id: 4,
		name: "basma mostafa",
		role: "Administrator",
		address: "minia",
		tel: "0",
		dep: "SASKE",
		img: "",
		alt: "",
	},
	{
		id: 5,
		name: "mohammed abdelhameed",
		role: "manager assistant",
		address: "minia",
		tel: "0",
		dep: "SASKE",
		img: raaef,
		alt: "",
	},
	{
		id: 3,
		name: "salem abdelkareem",
		role: "maintenance engineer",
		address: "minia",
		tel: "0",
		dep: "SEgOg",
		img: "",
		alt: "",
	},
	{
		id: 6,
		name: "ali mohammed",
		role: "network engineer",
		address: "minia",
		tel: "0",
		dep: "SINKROW",
		img: "",
		alt: "",
	},
	{
		id: 12,
		name: "khaled ahmed",
		role: "android & ios developer",
		address: "minia",
		tel: "0",
		dep: "Sacody",
		img: "",
		alt: "",
	},
	{
		id: 13,
		name: "islam shaban",
		role: "desktop developer",
		address: "minia",
		tel: "0",
		dep: "Sacody",
		img: eslam,
		alt: "",
	},
	{
		id: 7,
		name: "assem abdelkareem",
		role: "sales manager",
		address: "minia",
		tel: "0",
		dep: "STaaW",
		img: assem,
		alt: "",
	},
	{
		id: 8,
		name: "mohammed alaa",
		role: "marketing manager",
		address: "minia",
		tel: "0",
		dep: "STaaW",
		img: mido,
		alt: "",
	},
	{
		id: 9,
		name: "mohammed fathi",
		role: "salesman",
		address: "minia",
		tel: "0",
		dep: "STaaW",
		img: bofathey,
		alt: "",
	},
	{
		id: 10,
		name: "alaa hussein",
		role: "salesman",
		address: "minia",
		tel: "0",
		dep: "STaaW",
		img: alaa,
		alt: "",
	},
];
